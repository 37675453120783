<template>
  <div>
    <div class="main">
      <div class="banner">
        <img :src='data.banner'/>
      </div>
      <div class="product_intro">
        <div class="analysis">
          <span class="analysisText">{{ data.title }}行业痛点分析</span>
          <span class="analysisIntro"
          >{{ data.analysisDepict }}</span
          >
          <div class="analysisList">
            <div
                class="analysisListPart"
                v-for="(v, i) in data.analysisList"
                :key="i"
            >
              <p>{{ v.title }}</p>
              <span>{{ v.txt }}</span>
            </div>
          </div>
        </div>
        <div class="solution">
          <p class="solutionText">痛点解决方案</p>
          <span class="solutionIntro"
          >{{ data.programDepict }}</span
          >
        </div>

        <div class="product">
          <div class="product_box" v-for="(v, i) in data.programList" :key="i">
            <div v-if="i % 2 == 0" class="product_left">
              <img :src="v.img"/>
              <div class="info">
                <span class="title">{{ v.title }}</span>
                <div v-for="(r, e) in v.txt" :key="e">
                  <span class="textinfo">{{ r }}</span>
                  <span class="circle"></span>
                </div>
                <!--                <p class="englishinfo">{{ v.english_info }}</p>-->
              </div>
            </div>
            <div v-else class="product_right">
              <div class="info">
                <span class="title">{{ v.title }}</span>
                <div v-for="(r, e) in v.txt" :key="e">
                  <span class="circle"></span>
                  <span class="textinfo">{{ r }}</span>
                </div>
                <!--                <p class="englishinfo">{{ v.english_info }}</p>-->
              </div>
              <img :src="v.img"/>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {solutionInfo} from '@/api/solution';
import {scrolTop, Data} from "@/utils/ decorator";


export default {
  name: "solution",
  data() {
    return {
      data: {},
    };
  },
  @scrolTop
      @Data('solution')
  created() {
    this.data = this.d.find(r => r.id == this.$route.query.id);

  },
  methods: {
    async solutionInfo(id) {
      const {data, code, msg} = await solutionInfo(id);
      this.data = data;
    }
  },
};
</script>

<style lang="less" scoped>

.icon {
  font-family: iconfont;
  font-size: 45px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .banner {
    width: 100%;
    height: 528px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .product_intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .analysis {
      width: 1084px;
      height: 400px;
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .analysisText {
        height: 48px;
        font-size: 35px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
      }

      .analysisIntro {
        width: 690px;
        height: 29px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #999999;
        text-align: center;
        margin-top: 18px;
      }

      .analysisList {
        width: 100%;
        height: 220px;
        margin-top: 50px;
        display: flex;
        justify-content: space-around;

        .analysisListPart {
          height: 220px;
          width: 330px;
          background: #ffffff;
          box-shadow: 0px 2px 26px 1px rgba(0, 0, 0, 0.04);
          opacity: 0.62;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            width: 149px;
            height: 37px;
            line-height: 37px;
            opacity: 0.91;
            background-color: #1753b6;
            border-radius: 10px;
            text-align: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          span {
            margin-top: 25px;
            width: 235px;
            height: 49px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }

    .solution {
      width: 1084px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .solutionText {
        width: 288px;
        height: 48px;
        font-size: 30px;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .solutionIntro {
        width: 610px;
        height: 29px;
        font-size: 20px;
        text-align: center;
        margin-top: 15px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #999999;
      }
    }

    .intro {
      display: flex;
      justify-content: center;
    }

    .introList:nth-of-type(1) {
      border-radius: 10px 0 0 10px;
    }

    .introList:last-child {
      border-radius: 0 10px 10px 0;
    }

    .introList {
      border-bottom: 1px solid gainsboro;
      width: 150px;
      height: 150px;
      background-color: #ffffff;
      margin-top: -100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        color: gainsboro;
      }

      .text {
        width: 89px;
        height: 18px;
        font-size: 13px;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-top: 10px;
      }
    }

    .introList:hover {
      background: #ff5b5b;

      .icon,
      .text {
        color: white;
      }
    }

    .product {
      display: flex;
      flex-direction: column;

      .product_left {
        width: 1108px;
        height: 394px;
        margin-top: 50px;
        display: flex;

        img {
          width: 620px;
          height: 392px;
        }

        .info {
          width: 300px;
          margin-left: 100px;
          text-align: right;

          .title {
            text-align: right;
            width: 300px;
            height: 44px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          div:nth-of-type(1) {
            margin-top: 30px;
          }

          div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 300px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;

            .circle {
              width: 7px;
              height: 7px;
              background: #ff5b5b;
              border-radius: 50%;
              margin-left: 10px;
            }
          }

          .englishinfo {
            margin-top: 40px;
            width: 300px;
            height: 56px;
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #999999;
            line-height: 48px;
            opacity: 0.15;
          }
        }
      }

      .product_right {
        width: 1108px;
        height: 394px;
        margin-top: 100px;
        display: flex;

        img {
          width: 620px;
          height: 392px;
        }

        .info {
          width: 350px;
          margin-left: 50px;
          margin-right: 100px;
          text-align: left;

          .title {
            text-align: left;
            width: 350px;
            height: 44px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          div:nth-of-type(1) {
            margin-top: 30px;
          }

          div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 350px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;

            .circle {
              width: 7px;
              height: 7px;
              background: #ff5b5b;
              border-radius: 50%;
              margin-right: 10px;
            }
          }

          .englishinfo {
            margin-top: 40px;
            width: 350px;
            height: 56px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #999999;
            line-height: 48px;
            opacity: 0.15;
          }
        }
      }
    }

    .line {
      margin-bottom: 40px;
      margin-top: 20px;
      width: 1263px;
      height: 1px;
      background: #999999;
      opacity: 0.1;
    }
  }
}
</style>
